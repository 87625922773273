import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { isMobileApp } from 'utils/mobile';
import packageJson from '../../package.json';

const SERVICE_WEB = 'bilhold-frontend';
const SERVICE_APP = 'bilhold-app';

export const RUM_CONTEXT_ACCESS_TOKEN = 'accessToken';
export const RUM_CONTEXT_APP_VERSION = 'appVersion';
export const RUM_CONTEXT_APP_DEVICE = 'appDevice';

// This is a hacky polyfill to store stuff in sesison storage when using document.cookie.
// This is needed in the app to get datadog rum to work, because datadog-rum stores
// session data (in _dd_s) using document.cookie. The cookie isn't actually used in
// http requests, so it's ok to just use it like this
export const cookiePolyfill = () => {
    Object.defineProperty(document, 'cookie', {
        get: () =>
            Object.keys(sessionStorage)
                .map((key) => `${key}=${sessionStorage.getItem(key)}`)
                .join(';'),
        set: (s: string) => {
            const i = s.indexOf('=');
            const key = s.substring(0, i);
            const value = s.substring(i + 1);

            sessionStorage.setItem(key, value);
            return key + '=' + value;
        },
    });
};

const setAppContext = async () => {
    datadogRum.setGlobalContextProperty(
        RUM_CONTEXT_APP_VERSION,
        (await App.getInfo()).version
    );
    datadogRum.setGlobalContextProperty(
        RUM_CONTEXT_APP_DEVICE,
        (await Device.getInfo()).platform
    );
};

const setGlobalContext = () => {
    datadogRum.setGlobalContextProperty('themeBranding', {
        dealerLogo: 'Bilhold',
    });
    datadogRum.setGlobalContextProperty('carInfo', {
        model: '',
        brand: '',
    });
};

export const initDatadog = () => {
    const currentEnvironment = getCurrentEnvironment();
    let { serverUrl } = currentEnvironment;
    const { name: env } = currentEnvironment;
    if (env === 'local') {
        return;
    }
    try {
        serverUrl = new URL(serverUrl).origin;
    } catch {
        throw new Error('Could not create url');
    }
    const service = isMobileApp() ? SERVICE_APP : SERVICE_WEB;
    datadogLogs.init({
        clientToken: 'pubbff37927b9d85634afbc4bb6afb80e90',
        site: 'datadoghq.eu',
        service,
        env,
        version: packageJson.version,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        beforeSend: (log, context) => {
            if (context.isAborted) {
                return false;
            }

            if (log.message.includes('startTransition is not a function')) {
                return false;
            }

            return true;
        },
    });
    datadogRum.init({
        applicationId: '2497aa3e-702c-4041-acdf-fd83c1882bad',
        clientToken: 'pubbff37927b9d85634afbc4bb6afb80e90',
        site: 'datadoghq.eu',
        service,
        env,
        version: packageJson.version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask',
        enableExperimentalFeatures: ['clickmap', 'feature_flags'],
        allowedTracingUrls: [
            window.location.origin,
            serverUrl,
            /https:\/\/api(-test|-dev|-staging)+\.mittbilhold\.no.*/,
            /https:\/\/bilhold-api(\.prod|\.stage|\.test|\.dev)?\.mollercloud\.no.*/,
            /https:\/\/workshop-booking-port(\.prod|\.stage|\.test|\.dev)?\.mollercloud\.no.*/,
            /https:\/\/message-center(\.stage|\.test|\.dev|\.prod)?\.mollercloud\.no.*/,
            'https://booking-api.moller.no',
            'https://api.mittbilhold.no',
        ],
    });
    setGlobalContext();
    if (isMobileApp()) {
        void setAppContext();
    }
};

export type DDPrivacyLevels = 'mask-user-input' | 'mask' | 'allow';

export function ddRumPrivacySetting(privacyLevel: DDPrivacyLevels) {
    return { 'data-dd-privacy': privacyLevel };
}
