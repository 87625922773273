import { datadogLogs } from '@datadog/browser-logs';
import { matchesRegex } from '@moller/design-system/utilities/validation';
import { QueryClient } from '@tanstack/react-query';
import { ApiError } from 'openapi-typescript-fetch';
import { NetworkError } from '../../_api/http/booking';
import { ErrorWithUrl } from '../../_api/middlewares/errorUrlEnhancerMiddleware';
import {
    BaseFriendlyQueryError,
    FriendlyQueryWarning,
} from '../errors/PortError';

const status4x = new RegExp('4[0-9]*');

function getWrappedError(error: Error) {
    return error instanceof BaseFriendlyQueryError ? error.innerError : error;
}

function getFriendlyMessageIfWrapped(error: Error) {
    if (error instanceof BaseFriendlyQueryError)
        return { friendlyMessage: error.message };
}

function getApiError(error: unknown) {
    if (error instanceof ApiError) {
        return error;
    }
    if (
        error instanceof BaseFriendlyQueryError &&
        error.innerError instanceof ApiError
    ) {
        return error.innerError;
    }
}

const retryThreshold = 4;
export function getQueryClient() {
    const client = new QueryClient({
        defaultOptions: {
            mutations: {
                // NOTE: We don't actually retry mutations, but use this function to log mutation errors
                retry: <T,>(_failureCount: number, error: T): boolean => {
                    // If the client managed to reach the server, but there was an error, we get an ApiError
                    const apiError = getApiError(error);
                    // In the case of network errors, we don't get an ApiError. Therefore we pass errors through
                    // an errorUrlEnhancerMiddleware, where we add the request url.
                    const url =
                        apiError?.url ??
                        (error as ErrorWithUrl).fetcher_url ??
                        'UNKNOWN_URL';

                    if (error instanceof FriendlyQueryWarning) {
                        datadogLogs.logger.warn(
                            `Mutation warning: ${url}`,
                            {
                                url,
                                ...getFriendlyMessageIfWrapped(error),
                            },
                            getWrappedError(error)
                        );
                    } else if (error instanceof NetworkError) {
                        if (_failureCount < retryThreshold) {
                            datadogLogs.logger.warn(
                                `Retrying request due to status code 0: ${url}`,
                                {
                                    url,
                                    _failureCount,
                                },
                                getWrappedError(error)
                            );
                            return true;
                        }
                        datadogLogs.logger.error(
                            `Retries exceeded for statuscode 0. Mutation failed: ${url}`,
                            {
                                url,
                                ...getFriendlyMessageIfWrapped(error),
                                _failureCount,
                            },
                            getWrappedError(error)
                        );
                    } else if (error instanceof Error) {
                        datadogLogs.logger.error(
                            `Mutation failed: ${url}`,
                            {
                                url,
                                ...getFriendlyMessageIfWrapped(error),
                            },
                            getWrappedError(error)
                        );
                    } else {
                        datadogLogs.logger.error(
                            `Mutation failed with unknown error type: ${url}`,
                            { url, error }
                        );
                    }

                    return false;
                },
            },
            queries: {
                retry: <T,>(failureCount: number, error: T) => {
                    // If the client managed to reach the server, but there was an error, we get an ApiError
                    const apiError = getApiError(error);
                    // In the case of network errors, we don't get an ApiError. Therefore we pass errors through
                    // an errorUrlEnhancerMiddleware, where we add the request url.
                    const url =
                        apiError?.url ??
                        (error as ErrorWithUrl).fetcher_url ??
                        'UNKNOWN_URL';
                    if (error instanceof Error) {
                        if (
                            apiError &&
                            matchesRegex(status4x, apiError.status.toString())
                        ) {
                            datadogLogs.logger.warn(
                                `Query not retried due to 4xx status code: ${url}`,
                                {
                                    failureCount,
                                    url,
                                    ...getFriendlyMessageIfWrapped(error),
                                },
                                getWrappedError(error)
                            );
                            return false;
                        }
                        const shouldRetry = failureCount < retryThreshold;
                        if (!shouldRetry) {
                            datadogLogs.logger.error(
                                `Query retries exhausted: ${url}`,
                                {
                                    failureCount,
                                    url,
                                    ...getFriendlyMessageIfWrapped(error),
                                },
                                getWrappedError(error)
                            );
                        }
                        return shouldRetry;
                    }
                    datadogLogs.logger.error(
                        `Query not retried due to unknown error type: ${url}`,
                        { failureCount, url, error }
                    );
                    return false;
                },
            },
        },
    });

    return client;
}

export const STALE_TIME = { day: 8.6e7, hour: 3.6e6, minute: 6e4 };
