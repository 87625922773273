import { useQuery } from '@tanstack/react-query';
import { brandMapper } from 'src/types/Brand';
import Car from 'src/types/Car';
import { OrderedCar } from 'src/types/OrderedCar';
import { PendingCar } from 'src/types/PendingCar';
import proxyGet from 'src/utils/data-mocks/proxyGet';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export interface CarsResponse {
    existingCars: Car[];
    orderedCars: OrderedCar[];
    pendingCars: PendingCar[];
}

export const useCarProfile = () => {
    const { bilholdApiUrl } = getCurrentEnvironment();
    const apiUrl = `${bilholdApiUrl}/v1/cars`;

    return useQuery({
        queryKey: ['carProfile'],
        queryFn: () =>
            proxyGet<CarsResponse>(apiUrl).then((res) => {
                const newCarsData: CarsResponse = {
                    ...res.data,
                    existingCars: res.data.existingCars.map((car) => {
                        const mappedBrand = brandMapper(car.brand);
                        return {
                            ...car,
                            brand: mappedBrand,
                        };
                    }),
                    orderedCars: res.data.orderedCars.map((car) => {
                        const mappedBrand = brandMapper(car.brand);
                        return {
                            ...car,
                            brand: mappedBrand,
                        };
                    }),
                };
                return newCarsData;
            }),
    });
};
