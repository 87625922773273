import { datadogLogs } from '@datadog/browser-logs';
import { useMutation } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    BookingCreateModelQuery,
    BookingViewModel,
} from 'external-apis/src/types/port';
import { useState } from 'react';
import { FriendlyQueryError } from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';

const useCreateBookings = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher.path('/bookings').method('post').create();
};

export function useConfirmBooking(onSuccessBooking: () => void) {
    const [lc] = useLanguageContext();
    const confirmBooking = useCreateBookings();
    const [hasNetworkError, setHasNetworkError] = useState(false);
    return useMutation<
        BookingViewModel | undefined,
        Error,
        BookingCreateModelQuery
    >(
        (body: BookingCreateModelQuery) =>
            confirmBookingMutation(
                body,
                lc,
                confirmBooking,
                setHasNetworkError,
                hasNetworkError
            ),
        {
            onSuccess: () => {
                onSuccessBooking();
            },
        }
    );
}

export class NetworkError extends Error {
    status?: number;

    constructor(message: string, status?: number) {
        super(message);
        this.status = status;
    }
}

type PartialObject = Partial<Record<string, unknown>>;
function isObject(x: unknown): x is PartialObject {
    return typeof x === 'object';
}

function isNetworkError(x: unknown) {
    return isObject(x) && hasNoHttpStatus(x);
}

function hasNoHttpStatus(e: PartialObject): e is { status: number } {
    return !e.status;
}

async function confirmBookingMutation(
    body: BookingCreateModelQuery,
    lc: LanguageContextType,
    confirmBooking: ReturnType<typeof useCreateBookings>,
    setHasNetworkError: (hasNetworkError: boolean) => void,
    hasNetworkError: boolean
) {
    try {
        const result = await confirmBooking(body);
        setHasNetworkError(false);
        return result.data;
    } catch (e) {
        if (e instanceof confirmBooking.Error) {
            const response = e.getActualType();
            datadogLogs.logger.error(
                'Confirm booking mutation failed: ',
                response
            );

            switch (response.status) {
                case 409:
                    if (
                        response.data.errorType === 'TimeslotReservationExpired'
                    ) {
                        throw new FriendlyQueryError(
                            lc.errors.port_timeslots_confirm_timeout,
                            e,
                            response.status
                        );
                    }
                    throw new FriendlyQueryError(
                        lc.errors.default,
                        e,
                        response.status
                    );
                case 400:
                    if (hasNetworkError) {
                        datadogLogs.logger.info(
                            'Showing nice network error message to user on 400 error'
                        );
                        throw new FriendlyQueryError(
                            lc.errors.port_network_error_confirm_booking,
                            e,
                            response.status
                        );
                    }
                    throw new FriendlyQueryError(
                        lc.errors.default,
                        e,
                        response.status
                    );
                default:
                    throw new FriendlyQueryError(
                        lc.errors.default,
                        e,
                        response.status
                    );
            }
        }
        if (isNetworkError(e)) {
            if (e instanceof Error) {
                datadogLogs.logger.error(
                    'Throwing retryable network error',
                    {},
                    e
                );
            }
            setHasNetworkError(true);
            throw new NetworkError(lc.errors.default, e.status);
        }

        throw e;
    }
}
