import {
    AdditionalInfo,
    DealerSpecificInformation,
    ServiceCategory,
    ServiceType,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { useEffect, useState } from 'react';
import { ChooseDealerResult } from '../choose-dealer/ChooseDealerSection';
import { ChooseVehicleResult } from '../choose-vehicle/ChooseVehicleSection';
import { SectionState, checkDoneDependencies } from '../sectionUtils';

export interface FetchedData {
    availableRecommendedServices: ServiceViewModel[];
}

export interface StandardServicesData {
    selectedServices: {
        serviceId: string;
        adapterId: string;
        name: string;
        comment?: string;
        additionalInfo?: AdditionalInfo[];
        serviceCategory?: ServiceCategory;
        serviceType: ServiceType;
        dealerSpecificInformation: DealerSpecificInformation[];
    }[];
    fetchedData: FetchedData;
    mileage: string;
}

export type StandardServicesState = SectionState<StandardServicesData>;
export type StandardServicesDependencies = {
    chooseVehicle: ChooseVehicleResult;
    chooseDealer: ChooseDealerResult;
};

export function useStandardServices({
    initialState,
    dependencies,
}: {
    initialState: StandardServicesState;
    dependencies: StandardServicesDependencies;
}) {
    const [state, setState] = useState(initialState);
    const depsAreDone = checkDoneDependencies(dependencies);

    useEffect(() => {
        if (depsAreDone) {
            setState({ viewMode: 'edit' });
        } else {
            setState({ viewMode: 'hidden' });
        }
    }, [depsAreDone]);

    if (depsAreDone && state.viewMode === 'done') {
        const done = {
            result: {
                viewMode: state.viewMode,
                data: state.data,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
                data: state.data,
            },
        };
        return done;
    }

    if (depsAreDone && state.viewMode === 'edit') {
        const edit = {
            result: {
                viewMode: state.viewMode,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
            },
        };
        return edit;
    }

    return {
        result: {
            viewMode: 'hidden' as const,
        },
        props: {
            viewMode: 'hidden' as const,
            setSectionState: setState,
        },
    };
}
